<template>
  <div class="event-button" @click="btnClick" :style="{'margin-right': iconMarginR}">
    <div class="button-left">
      <div class="btn-icon-name" :class="{'whatsapp' : iconName == 'c-3-whatsapp'}">
        <svg-icon :svg-name="iconName" :width="width" :height="height" v-if="iconName.length !== 0 && !isClick"></svg-icon>
      </div>
      <div v-if="btnText" class="btn-text text-ellipsis">{{ btnText }}</div>
    </div>
    <div class="button-right" v-if="isShowRight">
      <svg-icon svg-name="direction-right" width="20px" height="20px" class="faq-item-svg"></svg-icon>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EventButton',
  data () {
    return {
    }
  },
  props: {
    isClick: {
      type: Boolean,
      default: false
    },
    // 图标名称
    iconName: {
      type: String,
      default: ''
    },
    // 图标右外边距
    iconMarginR: {
      type: String,
      default: '0px'
    },
    btnText: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '16'
    },
    height: {
      type: String,
      default: '16'
    },
    isShowRight: {
      type: Boolean,
      default: false
    },
    // 按钮发生点击时调用的父方法名称
    clickMethods: String,
    // 点击时的调用父方法传入的参数 可能出现的类型
    clickArg: [String, Number, Array, Object]
  },
  methods: {
    btnClick () {
      this.$emit('btnClick', this.clickMethods, this.clickArg)
    }
  }
}
</script>
<style lang="less">
  .event-button {
    display: flex;
    /* width: 100%; */
    overflow: hidden;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
  }
  .button-left{
    display: flex;
    overflow: hidden;
    align-items: center;
  }
  .btn-icon-name{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 10px;
    background-color: var(--primary-color);
    &.whatsapp{
      background-color: #25D366;
    }
    svg{
      filter: drop-shadow(34px 0 #fff);
      transform: translateX(-34px)
    }
  }
  
  .btn-text{
    margin-left: 8px;
    font-size: 14px;
    line-height: 22px;
    color: #121127;
  }
  .custom-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .event-button button {
    border: none;
    cursor: pointer;
    outline: none;
    background: transparent;
  }
</style>
