<template>
  <div class="trackCard theme-border-color">
    <div class="trackTitle" @click="toTrack">
      <div class="track-title-wrapper">
        <svg-icon svg-name="order-track-icon" width="20px" height="20px" class="faq-item-svg"></svg-icon>
        <p class="trackTitle-text">{{trackInfo.cardTitle}}</p>
      </div>
      <svg-icon svg-name="direction-right" width="20px" height="20px" class="faq-item-svg"></svg-icon>
    </div>
    <!-- 输入框 -->
    <!-- <div class="search-input" @click="toTrack">
      <svg-icon class="search-icon" svg-name="search-icon" width="14px" height="14px" style="position:relative;top:1px;"></svg-icon>
      <input :placeholder="trackSearch" type="text" />
    </div> -->
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  props: {
    trackInfo: Object
  },
  methods: {
    toTrack () {
      this.$store.commit('setPage', this.trackInfo.page)
    }
  },
  computed: {
  }
}
</script>
<style scoped>
  .trackCard {
    position: relative;
    height: auto;
    padding: 16px;
    background: #fff;
    /* box-shadow: 0px 4px 10px rgba(18, 17, 39, 0.1); */
    border-radius: 8px;
    margin-bottom: 16px;
    box-shadow: 0px 4px 10px rgba(18, 17, 39, 0.1), inset 0px 2px 0px rgba(44, 35, 229, 0.6);
  }

  .trackTitle {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    line-height: 24px;
  }

  .track-title-wrapper{
    display: flex;
    align-items: center;
  }
  .trackTitle-text{
    flex: 1;
    text-align: left;
    margin-left: 10px;
  }

  .search-input {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 30px;
    border: 1px solid #d1d1db;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12px;
    position: relative;
    margin: 12px 0 0;
  }

  input {
    border: none;
    font-size: 14px;
    height: 100%;
    width: 90%;
    margin-left: 4px;
  }

  input::placeholder {
    color: #6C6B80;
  }

  input:focus {
    outline: none;
  }

  .search-icon {
    cursor: pointer;
  }
</style>
