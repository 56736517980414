<template>
  <div class="home">
    <!-- 悬浮背景 -->
    <div class="welcome-header-bg home-bg-color"></div>

    <scroll-container :style-text="styleText" style="padding: 32px 16px;">
      <!-- 头部欢迎文字 -->
      <welcome-header :welcome-title="welcomeTitle" :welcome-text="welcomeText" :helloImg="willdeskLogo"></welcome-header>
      <!-- 卡片容器 -->
      <div class="card-container">
        <!-- 如果聊天及离线留言在后台都被关闭 则不渲染此卡片 -->
        <small-card v-if="chat.state || leaveMessage.state || whatsApp.state">
          <!-- <a class="go-whats-app" href="javascript:;" v-if="showApp">
            <img src="~assets/icons/whatsapp.png"  alt="WhatsApp">
          </a> -->
          <div class="online-status-wrapper">
            <span class="contact-us-text text-ellipsis">{{cardTitle}}</span>
            <div class="online-status"><span class="status-dot" :class="workTime == 0 ? 'online' : 'offline'"></span>{{workTime == 0 ? 'Online' : 'Offline'}}</div>
          </div>
          <!-- 欢迎小面板 -->
          <!-- <welcome-panel :chatText="calculateBtnText()" v-if="chat.state"></welcome-panel> -->
          <!-- <div class="panel-line" v-if="isShowLine"></div> -->
          <div class="welcome-buttons">
            <!-- 聊天按钮 -->
            <adaptive-button style="margin-right: 24px;" :class="{'whatsapp-btn' : defaultStateType == 'whatsApp'}" :icon-name="calculateBtnIconName()" :btn-padding="btnStylePadding()" @btnClick="btnClick" :btn-theme="chat.btnTheme" :btn-text="calculateBtnText()" :click-methods="calculateBtnClickMethods()" :click-arg="calculateBtnClickArgs()" btn-height="38px" font-size="14px" icon-width="20px" icon-height="20px" icon-margin-r="8px"></adaptive-button>
            <div class="welcome-buttons-right">
              <template v-for="(key) in widgetChannelSort">
                <template v-if="key == 'displayContactForm' && leaveMessage.state && defaultStateType != 'leaveMessage'">
                  <!-- 离线留言 -->
                  <event-button :key="key" :icon-margin-r="leaveMessage.state ? '24px' : '0'" @btnClick="btnClick" :click-methods="leaveMessage.clickMethods" :click-arg="leaveMessage.clickArg" icon-name="c-3-email" width="20" height="20"></event-button>
                </template>
                <template v-if="key == 'whatsApp' && whatsApp.state && defaultStateType != 'whatsApp'">
                  <!-- what's app -->
                  <event-button :key="key" :icon-margin-r="whatsApp.state ? '24px' : '0'" @btnClick="btnClick" click-methods="setNewWindow" :click-arg="whatsApp.url" icon-name="c-3-whatsapp" width="20" height="20"></event-button>
                </template>
              </template>
            </div>

            <!-- <template v-for="(key) in widgetChannelSort">
              <template v-if="key === 'displayContactForm'">
                <event-button :key="key" v-if="checkForShowLeaveMessage" icon-margin-r="0px" @btnClick="btnClick" :click-methods="leaveMessage.clickMethods" :click-arg="leaveMessage.clickArg" icon-name="email" width="24" height="24" :btn-text="leaveMessage.btnText" :isShowRight="isShowRight"></event-button>
              </template>
              <template v-if="key === 'whatsApp'">
                <event-button :key="key" v-if="checkForShowWhatsapp" @btnClick="btnClick" click-methods="setNewWindow" :click-arg="whatsApp.url" icon-name="whatsapp" width="24" height="24" :btn-text="whatsApp.btnText" :isShowRight="isShowRight"></event-button>
              </template>
            </template> -->
          </div>
        </small-card>
        <template v-for="(key) in widgetBlockSort">
          <template v-if="key === 'orderTrack'">
            <!-- track order 板块 -->
            <track-card :key="key" :trackInfo="orderTrackInfoData" v-if="orderTrackSwitch == '1'"></track-card>
          </template>
          <!-- <template v-else-if="key === 'afterSalesOrderSwitch'">
            <track-card :key="key" :trackInfo="afterSalesOrder" v-if="afterSalesOrderSwitch == '1'"></track-card>
          </template> -->
          <template v-else-if="key === 'showFaq'">
            <!-- FAQ问题列表 -->
            <faq-card :key="key" :faq-data="faq" v-if="faq.state"></faq-card>
          </template>
        </template>
        <div class="app-version">- v.{{ $version }} -</div>
      </div>
      <logo-bar v-if="logoBarState" style-text="position:fixed;left:0;right:0;bottom:0;z-index:99;"></logo-bar>
    </scroll-container>
  </div>
</template>

<script>
import WelcomeHeader from '@/views/home/children/WelcomeHeader'
import SmallCard from '@/views/home/children/SmallCard'
import AdaptiveButton from 'components/contents/AdaptiveButton'
import EventButton from 'components/contents/EventButton'
import FaqCard from '@/views/home/children/FaqCard'
import trackCard from './children/TrackCard'
import LogoBar from 'components/contents/LogoBar'
import ScrollContainer from 'components/contents/ScrollContainer'
import WelcomePanel from './children/WelcomePanel'
import { mapGetters } from 'vuex'
// import { v4 as uuidv4 } from 'uuid'
// import { setCache } from '@/utils/helper'
// import { postFrameMessage, listenFrameMessage } from "@/utils/iframeMessage";
export default {
  name: 'Home',
  data () {
    return {
      welcomeTitle: 'Hi Kelly 👋',
      welcomeText: 'Contact us or find help articles for support',
      willdeskLogo: '',
      // 需要到后端api请求
      cardTitle: '', // 'Contact us'
      chat: {
        // 状态
        state: true,
        // 文字
        btnText: '',
        // 按钮主题
        btnTheme: 'blue',
        // 点击时调用父组件方法
        clickMethods: 'setPage',
        // 传回的参数
        clickArg: 'chat'
      },
      // 留言
      leaveMessage: {
        // 状态
        state: true,
        // 文字
        btnText: 'Email us',
        // 按钮主题
        btnTheme: 'white',
        // 点击时调用父组件方法
        clickMethods: 'setPage',
        // 传回的参数
        clickArg: 'leave-message'
      },
      whatsApp: {
        state: true,
        url: '',
        clickMethods: 'setNewWindow',
        clickArs: 'whatsapps',
        btnText: 'whatapps us'
      },

      faq: {
        state: true,
        titleText: 'Help Center',
        categoryText: 'Categories',
        placeholder: 'Search for support',
        // faq详情页url
        url: '#',
        list: [
          { id: 1, ArticleTitle: 'How to charge my phone properly?', url: '#' },
          { id: 2, ArticleTitle: 'How to avoid my phone overheat?', url: '#' },
          {
            id: 3,
            ArticleTitle: 'What if my handset warranty is expiring during the lockdown period?',
            url: '#'
          },
          {
            id: 4,
            ArticleTitle: 'Why can my phone be unlocked through Face Access when I wearing a mask？',
            url: '#'
          }
        ],
        categoryList: [
          { id: 1, categoryName: 'Order & Shipping', url: '#', categoryIcon: '#icon-communications_help' },
          { id: 2, categoryName: 'Product', url: '#', categoryIcon: '#icon-communications_send' },
          {
            id: 3,
            categoryName: 'Return & Exchanges',
            url: '#',
            categoryIcon: '#icon-shipping_fastdelivery'
          }
        ]
      },
      orderTrackInfo: {
        state: true,
        cardTitle:"",

      },
      orderTrack: true,
      logoBar: true,
      showApp: true,
      logoBarState: false,
      widgetChannelSort: ['displayContactForm', 'whatsApp'], // channel模块的排序
      widgetBlockSort: ['orderTrack', 'afterSalesOrderSwitch', 'showFaq'], // block模块的排序
      defaultStateType: 'liveChat', // 默认大按钮的类型 [liveChat, leaveMessage, whatsApp]
      orderTrackSort:['orderTrack', 'afterSalesOrderSwitch']
    }
  },
  components: {
    WelcomeHeader,
    SmallCard,
    AdaptiveButton,
    FaqCard,
    LogoBar,
    ScrollContainer,
    WelcomePanel,
    trackCard,
    EventButton
  },
  computed: {
    ...mapGetters({
      automationStatus: 'automation/automationStatus',
      workTime: 'workTime'
    }),
    // 检验是否显示emailus
    checkForShowLeaveMessage () {
      return this.leaveMessage.state
    },
    // 检验是否显示whatsapp
    checkForShowWhatsapp () {
      return this.whatsApp.state
    },
    styleText () {
      // console.log(this.$store.state.boxPadding);
      // 移动端pc端
      return ('height:calc(100%);top:0;padding:26px ' + this.$store.state.boxPadding + ';')
    },
    btnStylePadding () {
      return function () {
        const btnText = this.calculateBtnText()
        // 判断状态，有且仅有一个状态为true时，按钮展示大内边距，else为小内边距
        return btnText.length > 20 ? '8px 14px' : '8px 24px'
      }
    },
    calculateBtnText () {
      return function () {
        const type = this.checkConnectStatus()
        if (type === 'liveChat') {
          this.defaultStateType = 'liveChat'
          return this.chat.btnText
        } else if (type === 'leaveMessage') {
          this.defaultStateType = 'leaveMessage'
          return this.leaveMessage.btnText
        } else if (type === 'whatsApp') {
          this.defaultStateType = 'whatsApp'
          return this.whatsApp.btnText
        }
      }
    },
    calculateBtnIconName () {
      return function () {
        const type = this.checkConnectStatus()
        if (type === 'liveChat') {
          return 'send'
        } else if (type === 'leaveMessage') {
          return 'btn-email'
        } else if (type === 'whatsApp') {
          return 'btn-whatsapp'
        }
      }
    },
    calculateBtnClickMethods () {
      return function () {
        const type = this.checkConnectStatus()
        if (type === 'liveChat') {
          return this.chat.clickMethods
        } else if (type === 'leaveMessage') {
          return this.leaveMessage.clickMethods
        } else if (type === 'whatsApp') {
          return this.whatsApp.clickMethods
        }
      }
    },
    calculateBtnClickArgs () {
      return function () {
        const type = this.checkConnectStatus()
        if (type === 'liveChat') {
          return this.chat.clickArg
        } else if (type === 'leaveMessage') {
          return this.leaveMessage.clickArg
        } else if (type === 'whatsApp') {
          return this.whatsApp.url
        }
      }
    },
    textMaxWidth: function () {
      // return function () {
      if (this.leaveMessage.state && this.whatsApp.state) {
        return '168px'
      } else if (this.leaveMessage.state || this.whatsApp.state) {
        return '204px'
      } else {
        return '252px'
      }
      // }
    },
    isShowLine () {
      return this.chat.state && (this.whatsApp.state || this.leaveMessage.state)
    },
    isShowRight () {
      return !(this.whatsApp.state && this.leaveMessage.state)
    },
    shopSetting(){
      return this.$store.state.shopSetting
    },
    textSetting () {
      return JSON.parse(this.shopSetting.liveChat['0'])['text-settings']
    },
    order () {
      return JSON.parse(this.textSetting.order)
    },
    emailBtnText () {
      return this.textSetting.email_us_button
    },
    whatsappBtnText () {
      return this.textSetting.whatsappUsButton
    },
    orderTrackSwitch () {
      return this.shopSetting.WidgetStatus && this.shopSetting.WidgetStatus[0].OrderTrack
    },
    afterSalesOrderSwitch () {
      return this.shopSetting.WidgetStatus && this.shopSetting.WidgetStatus[0].afterSalesOrderSwitch
    },
    // homeOffline(){
    //   return this.getCurrLangFixedTranslate.home_offline
    // },
    // homeOnline(){
    //   return this.getCurrLangFixedTranslate.home_online
    // },
    orderTrackInfoData () {
      return {
        cardTitle: this.textSetting.order_track_title || 'Order Tracking',
        page:'OrderSearch'
      }
    },
    afterSalesOrder () {
      return {
        cardTitle: this.order.afterSalesOrderTitle || 'After-sales Order Self-service',
        page:'OrderEmailLogin'
      }
    }
  },
  methods: {
    // 检测当前按钮的所属状态
    checkConnectStatus () {
      if (this.chat.state) {
        return 'liveChat'
      } else {
        for (let i = 0; i < this.widgetChannelSort.length; i++) {
          const item = this.widgetChannelSort[i]
          if (item === 'displayContactForm' && this.leaveMessage.state) {
            return 'leaveMessage'
          }
          if (item === 'whatsApp' && this.whatsApp.state) {
            return 'whatsApp'
          }
        }
      }
    },
    syncData () {
      const shopSetting = this.$store.state.shopSetting
      if (!shopSetting.WidgetStatus) return
      if (shopSetting.WidgetStatus[0].EnableLiveChat === '0' && shopSetting.WidgetStatus[0].ShowFaq === '0' && shopSetting.WidgetStatus[0].DisplayContentForm === '0') {
        this.$store.dispatch('setSiteState', false)
      }
      this.welcomeTitle = shopSetting.WelcomeMessage[0].Greetings
      this.welcomeText = shopSetting.WelcomeMessage[0].Introduction
      this.willdeskLogo = shopSetting.WelcomeMessage[0].websiteLogo
      this.chat.state = Boolean(Number(shopSetting.WidgetStatus[0].EnableLiveChat))
      this.faq.state = Boolean(Number(shopSetting.WidgetStatus[0].ShowFaq))
      this.whatsApp.state = Boolean(Number(shopSetting.WidgetStatus[0].WhatsApp)) && shopSetting.WidgetStatus[0].ContactNumber.length != 0
      this.orderTrack = Boolean(Number(shopSetting.WidgetStatus[0].OrderTrack))
      this.orderTrackInfo.state = this.orderTrack
      // this.logoBar = Boolean(Number(shopSetting.WidgetStatus[0].LogoBar))
      this.showApp = Boolean(Number(shopSetting.WidgetStatus[0].WhatsApp))
      // that.faq.state = true
      this.leaveMessage.state = Boolean(Number(shopSetting.WidgetStatus[0].DisplayContactForm))

      const TextSetting = JSON.parse(shopSetting.liveChat['0'])['text-settings']
      this.cardTitle = TextSetting.contact_us_title
      this.chat.btnText = TextSetting.contact_us_button
      this.leaveMessage.btnText = TextSetting.email_us_button
      this.whatsApp.btnText = TextSetting.whatsappUsButton
      this.faq.titleText = TextSetting.help_center_title
      this.faq.categoryText = TextSetting.help_center_category_title
      this.faq.placeholder = TextSetting.article_search
      this.whatsApp.url = 'https://wa.me/' + Number(shopSetting.WidgetStatus[0].ContactNumber)
      this.orderTrackInfo.textSetting = TextSetting
      const logoBar = shopSetting?.WidgetStatus[0]?.LogoBar || {}
      const time = parseInt(new Date().getTime() / 1000)
      this.logoBarState = (logoBar !== '-1' && time > logoBar) || logoBar === '0'
      try {
        this.widgetChannelSort = shopSetting.WidgetStatus[0].widgetChannelSort ? JSON.parse(shopSetting.WidgetStatus[0].widgetChannelSort) : ['displayContactForm', 'whatsApp']
      } catch (e) {}
      try {
        this.widgetBlockSort = shopSetting.WidgetStatus[0].widgetBlockSort ? JSON.parse(shopSetting.WidgetStatus[0].widgetBlockSort) : ['orderTrack', 'afterSalesOrderSwitch', 'showFaq']
      } catch (e) {}
      try {
        this.orderTrackSort = shopSetting.WidgetStatus[0].orderTrackSort ? JSON.parse(shopSetting.WidgetStatus[0].orderTrackSort) : ['orderTrack', 'afterSalesOrderSwitch']
      } catch (e) {}
      if (!this.widgetBlockSort.includes('afterSalesOrderSwitch')) {
        let orderTrackIndex = this.widgetBlockSort.indexOf('orderTrack')
        this.widgetBlockSort.splice(orderTrackIndex, 1, ...this.orderTrackSort)
      }
    },
    setPage (page, self) {
      const shopSetting = this.$store.state.shopSetting
      if (page === 'login' && !this.$store.state.login) {
        if (shopSetting.VisitorCollection[0].DataCollection === '1') {
          this.$store.commit('setPage', page)
        } else {
          this.$store.commit('setPage', 'chat')
        }
      } else {
        this.$store.commit('setPage', page)
      }
    },
    // WhatsApp新窗口
    setNewWindow (path) {
      window.open(path, '_blank')
    },
    // 按钮点击事件
    /**
       * @param string methods 要调用的方法名称
       * @param arg 参数
       */
    btnClick (methods, arg) {
      console.log('点击的', methods, arg)
      if (this.automationStatus.login && arg === 'login') {
        return this.$store.commit('setPage', 'chat')
      }
      // 调用方法 传入当前this指向 否则this是指向$options的
      // this.$options.methods[methods](arg, this);
      if (!methods && !arg) {
        return
      }
      this[methods](arg)
      // console.log(this);
    },
    // 设置toast气泡框样式
    setToastState (flag) {
      this.toastState = flag
    }
  },
  watch: {
    '$store.state.shopSetting' (current) {
      this.syncData()
    }
  },
  mounted () {
    this.syncData()
  }
}
</script>

<style lang="less">
  .home {
    /* padding: 26px 26px 0 26px; */
    background: #f8f8f8;
    padding: 26px;
  }

  /* 欢迎语背景 需要根据主题来变化 */
  .home .welcome-header-bg {
    position: fixed;
    width: 100%;
    height: 280px;
    top: 0;
    left: 0;
    /* background: linear-gradient(180deg, #636eff 0%, #3b59fe 100%); */
    background: #3b59fe;
  }

  .home .card-container {
    height: auto;
    padding-top: 24px;
  }
  .online-status-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    .contact-us-text{
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    .online-status{
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      display: flex;
      margin-left: 10px;
      align-items: center;
      white-space: nowrap;
      .status-dot{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 8px;
        &.online{
          background-color: rgba(82, 196, 26, 1);
        }
        &.offline{
          background-color: orange;
        }
      }
    }
  }

  .home .go-whats-app{
    position: absolute;
    display: block;
    text-decoration: none;
    right: 22px;
    top: 11px;
    width: 42px;
    height: 42px;
    cursor: pointer;
  }

  .home .go-whats-app img{
    width: 42px;
    height: 42px;
  }
  .panel-line{
    margin: 12px 0;
    height: 1px;
    background-color: rgba(229, 229, 235, 1);
  }
  .welcome-buttons {
    display: flex;
    align-items: center;
    .welcome-buttons-right{
      flex: 1;
      display: flex;
      align-items: center;
    }
    .adaptive-button{
      max-width: 190px;
      &:last-child{
        margin-right: 0;
      }
      button{
        border-radius: 6px;
      }
    }
    .event-button{
      &:last-child{
        margin-right: 0 !important;
      }
    }
    .whatsapp-btn{
      button{
        background-color: #25D366 !important;
      }
    }
  }
  .app-version {
    padding: 2px 0 12px;
    font-size: 12px;
    color: #e8e8e8;
  }
</style>
