<template>
  <div class="orderSearch">
    <nav-bar :back-page="backPage">
      <template #left>
        <svg-icon svg-name="left-arrow" width="20px" height="20px"></svg-icon>
      </template>
      <template #center>
        <div class="nav_bar_center_os text-ellipsis">
          {{ navTitle }}
        </div>
      </template>
    </nav-bar>

    <div class="content">
      <div class="findType">
        <button class="defaultType"><div class="text-ellipsis">{{ yourEmailText }}</div><span class="default_border theme-solid-btn-color"></span></button>
      </div>
      <div class="inputForm">
        <input type="text" v-model.trim="loginEmail" class="orderInput" style="margin-top: 20px;" :placeholder="emailPlaceholdText" @input="handleInput($event,'loginEmailError')">
        <p class="inputTips" v-if="loginEmailError">{{ emailErrorText }}</p>
        <div class="email--code-wrapper">
          <input type="text" v-model.trim="loginEmailCode" class="orderInput code" :placeholder="codePlaceholdText" @input="handleInput($event,'loginEmailCodeError')">
          <div class="code-send text-ellipsis">
            <span @click="handleSendEmailCode" v-if="isShowSend" class="code-send-text" :class="{'disabled' : disabledSend}">{{ sendTextLang }}</span>
            <span v-else class="code-send-text">{{ countDown }} s</span>
          </div>
        </div>
        <p class="inputTips" v-if="loginEmailCodeError">{{ codeErrorText }}</p>
        <p class="inputTips success" v-if="loginEmailCodeSend">{{ sendSuccessText }}</p>
      </div>
      <!-- 开始查询按钮 -->
      <div class="search">
        <adaptive-button @btnClick="search" btn-theme="blue" fontSize="14px" iconWidth="20px" iconHeight="20px" :btn-text="buttonText" click-methods="search" click-arg="Track" btnHeight="38px" iconMarginR='8px' iconName='order-search-icon' :isLoading="trackLoading"></adaptive-button>
        <!-- <p class="inputTips">You’ve entered an incorrect code too may times, please try again in 1 hour.</p> -->
        <div class="change-find-type" @click="changeFindType">{{ changeOrderText }}  ></div>
      </div>
      <!-- 这里需要注意 引导用户跳转shopify登录且用户的登录信息这些需要不存在 -->
      <!-- <div class="login-shopify theme-link-text-color" v-if="!getUrlParam('user_id') && !login && shopifyLoginReminder" @click="toLoginByShopify">
        <div class="login-button"> {{ shopifyLoginReminder }} ></div>
      </div> -->
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/contents/NavBar'
import AdaptiveButton from '@/components/contents/AdaptiveButton'
import { mapGetters, mapMutations, mapState } from 'vuex'
// import { checkEmail } from '@/utils/reg'
export default {
  data () {
    return {
      loginEmail:'', // 登录邮箱
      loginEmailCode:'', // 邮箱验证码
      loginEmailError:false,
      loginEmailCodeError:false,
      error: {
        email:{
          active:'empty'
        },
        code:{
          active:'empty'
        }
      },
      loginEmailCodeSend:false, // 验证码发送成功的提醒
      backPage: 'home',
      countDown: 60, // 验证码发送倒计时
      sendText:'send',
      isShowSend:true,
      countTime: null, // 计时器
      trackLoading: false,
      disabledSend: false
    }
  },
  components: {
    NavBar,
    AdaptiveButton
  },
  computed: {
    ...mapState({
      login: 'login',
      userInfo: "userInfo",
    }),
    ...mapGetters({
    }),
    shopifyLoginReminder () {
      return ''
    },
    iconWidth () {
      return this.$store.state.isMobile ? '10px' : '8px'
    },
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    },
    order () {
      return JSON.parse(this.textSetting.order)
    },
    // 多语言部分
    navTitle () {
      // return this.getShopsetting.orderTrackTitle
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[27] : "Track and manage order "
    },
    buttonText () {
      return this.order.trackLoginButton || 'Log in'
    },
    yourEmailText(){
      return this.order.trackYourEmail || "Your email"
    },
    emailPlaceholdText(){
      return this.order.trackEnterEmailPlaceholder || "Enter your email to log in"
    },
    codePlaceholdText(){
      return this.order.trackEnterCodePlaceholder || "Enter code"
    },
    sendTextLang(){
      let str = ''
      if(this.sendText == 'send'){
        str = this.order.trackSendButton || "Send"
      }else{
        str = this.order.trackResendButton || "Resend"
      }
      return str
    },
    changeOrderText(){
      return this.order.useOrderTrack || "Use order# or tracking# to track"
    },
    emailErrorText(){
      let str = ''
      if(this.error.email.active == 'empty'){
        str = this.$store.state.language.error[2] || "E-mail can not be empty"
      }else{
        str = this.$store.state.language.error[3] || "Invalid email address"
      }
      return str
    },
    codeErrorText(){
      let str = ''
      if(this.error.code.active == 'empty'){
        str = this.$store.state.language.error[4] || "verification code must be filled"
      }else{
        str = this.$store.state.language.error[5] || "Verification code error"
      }
      return str
    },
    sendSuccessText(){
      return this.$store.state.language.error[6] || "An code has been sent to the email."
    },
    orderTrackSwitch () {
      return this.$store.state.shopSetting.WidgetStatus && this.$store.state.shopSetting.WidgetStatus[0].OrderTrack
    }
  },
  watch: {
  },
  methods: {
    ...mapMutations({
      setPage:'setPage',
      setVerifyCodeErrorTime:'order/setVerifyCodeErrorTime',
      setVerifyEmail:'order/setVerifyEmail',
      setHistory:'setHistory'
    }),
    resetData(){
      this.loginEmail =''
      this.loginEmailCode =''
    },
    // 验证邮箱并跳转订单列表
    search () {
      let flag = false
      // if(!this.loginEmail){
      //   this.loginEmailError = true
      //   flag = true
      // }
      // if(!this.loginEmailCode){
      //   this.loginEmailCodeError = true
      //   flag = true
      // }
      // if(flag){
      //   return
      // }
      this.loginEmailCodeSend = false
      this.trackLoading = true
      this.setPage('OrderList')
    },
    handleInput(e,errorName){
      if(e.target.value){
        this[errorName] = false
      }
    },
    // 发送验证码
    handleSendEmailCode(){
      if(this.sendText == 'send'){
        this.sendText = 'reSend'
      }else{
        this.sendText = 'send'
      }

      if(!this.loginEmail){
        this.loginEmailError = true
        return
      }
      // else{
      //   if(!checkEmail(this.loginEmail)){
      //     this.loginEmailError = true
      //     return
      //   }
      // }
      this.disabledSend = true
    },
    startSendCountDown(){
      clearInterval(this.countTime)
      this.countDown = 60
       this.countTime = setInterval(()=>{
        this.countDown--
        if(this.countDown == 0){
          this.sendText = 'reSend'
          this.isShowSend = true
          clearInterval(this.countTime)
        }
      },1000)
    },
    changeFindType(){
      this.setPage('OrderSearch')
    }
  },
  created(){
    // this.sendText = this.sendTextLang
  }
}
</script>
<style scoped>
  .login-shopify {
    width: calc(100%);
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    cursor: pointer;
  }
  .content {
    width: 100%;
    padding: 0 13px 16px;
    position: relative;
  }

  .findType {
    width: 100%;
    height: 54px;
    /* display: flex;
    justify-content: space-between; */
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .findType button {
    border: none;
    background: #fff;
    width: 160px;
    height: 54px;
    font-size: 14px;
    font-weight: 400;
    color: #6C6B80;
    line-height: 54px;
    line-height: 14px;
  }

  .findType button span {
    cursor: pointer;
  }

  .defaultType {
    position: relative;
    color: #000 !important;
    font-weight: 500 !important;
  }

  .defaultType .default_border {
    position: absolute;
    left: 0;
    bottom: -4px;
    display: inline-block;
    width: 100%;
    height: 4px;
    background-color: #2C23E5;
    border-radius: 4px;
  }

  .inputForm {
    padding: 0 3px;
  }
  .email--code-wrapper{
    margin-top: 20px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
  }
  .code-send{
    line-height: 40px;
    font-size: 16px;
    padding: 0 8px;
    width:130px;
    font-weight: 700;
    border-left: 1px solid #D9D9D9;
    cursor: pointer;
    text-align: center;
  }
  .code-send-text{
    white-space: nowrap;
    color: var(--primary-color);
  }
  .code-send-text.disabled{
    color: #A3A2A8;
    cursor: not-allowed;
  }

  .orderInput {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    margin-left: 0 !important;
  }
  .orderInput.code{
    border: 0;
  }

  .orderInput.error-input {
    border: 1px solid #F5222D;
    box-shadow: 0px 0px 0px 2px rgba(255, 0, 0, 0.2);
    border-radius: 4px;
  }
  .orderInput::-webkit-input-placeholder{   /* 使用webkit内核的浏览器 */
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }
  .orderInput:-moz-placeholder{    /* Firefox版本4-18 */
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }

  .orderInput::-moz-placeholder{    /* Firefox版本19+ */
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }

  .orderInput:-ms-input-placeholder{   /* IE浏览器 */
    color: rgba(0, 0, 0, 0.25);
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }

  .orderInput:hover,
  .orderInput:focus {
    outline: 2px solid var(--input-hover-color);
    border-radius: 4px;
  }

  .search {
    margin: 48px auto 0;
    /* width: 340px; */
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    
  }
  .change-find-type{
    margin-top: 24px;
    display: inline-block;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
  }

  .inputTips {
    margin-top: 4px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #F5222D;
    line-height: 22px;
  }
  .inputTips.success{
    color: #52C41A;
  }

  .inputContent {
    position: relative;
  }
  .nav_bar_center_os {
    /* margin-left: 24px; */
  }
</style>
