<template>
  <div class="customer" :style="styleText">
    <div class="customer-message-container">
      <!-- 文字消息 -->
      <div class="customer-message theme-solid-bg-color theme-text-color" v-if="chatData.messageType === 'text'">
        <!-- 保留用户输入格式 <pre>{{chatData.message}}</pre> -->
        <div v-html="emojiToImg"></div>
        <!-- <pre>{{ chatData.message }}</pre> -->
      </div>
      <!-- 附件 -->
      <div class="customer-attachment theme-solid-bg-color theme-text-color" v-if="chatData.messageType === 'attachment'" @click="downloadFile">
        <svg-icon svg-name="attach-files" width="16px" height="16px" style="margin-right:6px;" ></svg-icon><span>{{chatData.message}}</span>
        <progress-bar :progress="progress" v-if="isDownloading" @closeClick="cancelDownloadFunc('Cancel download')"></progress-bar>
        <a @click.stop class="download" :href="downloadURL" :download="chatData.message" target="_blank" style="display:none;"></a>
      </div>
      <!-- 图片 图片未加载时用容器进行占位 -->
      <div class="customer-img" v-if="chatData.messageType === 'img'" @click="viewImg(chatData.src)">
        <img :src="chatData.src" alt="image-message" :style="imgSizeStyle" @load="scrollBottom" />
      </div>
      <div class="customer-order" v-if="chatData.messageType === 'order'">
        <!-- 订单信息 -->
        <div class="customer-orderInfo">
          <!-- 订单状态 -->
          <div class="orderStatus">
            <div class="orderTitle">
              <p class="order-number">Order: <span>{{ chatData.content.orderNumber }}</span></p>
              <div class="financialStatus">{{ chatData.content.financialStatus }}</div>
            </div>
            <!-- <p>{{ localCreateTime}}</p> -->
          </div>
          <div class="orderDetail">
            <div class="orderImg">
              <img :src="orderImg" alt="">
            </div>
            <div class="detailNumber">
              <p>Total items</p>
              <p>{{ chatData.content.totalNumber }}</p>
            </div>
            <div class="detailAmount">
              <p>Total amount</p>
              <p>{{ chatData.content.totalAmount }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 消息状态 -->
      <!-- <div class="customer-message-state" v-if="chatData.messageState < 2" @click="resend">
        <message-state :state.sync="chatData.messageState"></message-state>
      </div> -->
    </div>
  </div>
</template>

<script>
// import MessageState from './MessageState'
import ProgressBar from '@/components/contents/ProgressBar'
// import { getEmojiByAlt, getImgSizeFromSrc, calcImgSize, idLink } from '@/utils/helper'
import { getEmojiByAlt } from '@/utils/helper'
import proImgError from '@/assets/img/proImgError.png'
// import { postFrameMessage } from '@/utils/iframeMessage'
// import { reSendFile } from '@/utils/uploadFile'
// import axios from 'axios'
// 消费者/顾客消息组件
export default {
  name: 'Customer',
  components: {
    // MessageState,
    ProgressBar
  },
  computed: {
    styleText () {
      const left = Number(this.$store.state.boxPadding.replace('px', '')) * 2
      // console.log(left);
      const bottom = this.messageState < 2 ? '30px' : '26px'
      return ('margin:26px ' + this.$store.state.boxPadding + ' ' + bottom + ' ' + left + 'px;')
    },
    emojiToImg () {
      return getEmojiByAlt(this.chatData.message)
    },
    orderImg(){
      return this.chatData.content.img_url || this.proImgError

    }
    // 图片占位图
    // imgSizeStyle () {
    //   const src = getImgSizeFromSrc(this.chatData.src)
    //   const scale = src && calcImgSize(src.width, src.height)
    //   console.log(src)
    //   console.log(scale)
    //   return { width: scale.width ? scale.width + 'px' : '', height: scale.height ? scale.height + 'px' : '' }
    // }
  },
  created () {
    // this.chatData.messageState = 0
    // console.log(this.chatData)
    this.loadScroll = this.chatData.messageType === 'img' && this.chatData.src.indexOf('blob:') !== -1
  },
  props: {
    /* messageState: {
                  type: Number,
                  default: 2
                }, */
    chatData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      // 传入类型 0 失败/fail 1 loading 2 成功
      // messageState: 0
      // 文件的本地url下载地址
      proImgError,
      downloadURL: null,
      // 当前的下载进度
      progress: 0,
      // 是否正在下载
      isDownloading: false,
      // 取消下载的方法
      cancelDownloadFunc: null,
      // 图片是否在加载中
      imgLoading: false,
      // 图片加载结束是否需要滚动到底部
      loadScroll: false
    }
  },
  methods: {
    // 消息重发
    // resend () {
    // 如果消息状态不是失败 则直接返回即可
    // if (this.chatData.messageState !== 0) return

    // // 开始重发逻辑
    // console.log('消息重发逻辑 ----> ' + this.chatData.message)
    // // 获取当前时间戳 重发消息时间戳以最新为准
    // const timestamp = new Date().getTime()
    // if (_ws === null) console.log('出问题了，小老弟 customer')

    // const that = this

    // // 图片和文件重发
    // if (this.chatData.messageType === 'img' || this.chatData.messageType === 'attachment') {
    //   const newChatData = this.chatData
    //   // newChatData.timeStamp = timestamp 更新时间会导致无法找到旧消息
    //   newChatData.messageState = 1
    //   this.$emit('update:chatData', newChatData)
    //   reSendFile(this.chatData.file, this.$store.state.userInfo, this.chatData.messageType, this.chatData.timeStamp)
    //   return false
    // }
    // 组织发送格式 发往服务器
    // const sendJson = {
    //   format: 'message',
    //   type: 'text',
    //   // 当前顾客id 在顾客登录时需要将id存入vuex
    //   uid: this.$store.state.userInfo.uid,
    //   data: this.chatData.message,
    //   target: 'no', // 不可省略字段
    //   timeStamp: String(timestamp), // 唯一标识:毫秒级时间戳
    //   uname: that.$store.state.userInfo.userName,
    //   facesColor: that.$store.state.userInfo.facesColor
    // }
    // console.log(JSON.stringify(sendJson));
    // const newChatData = {
    //   message: that.chatData.message,
    //   messageState: 1,
    //   timeStamp: String(timestamp),
    //   type: 'Customer',
    //   messageType: 'text'
    // }
    // this.$emit('update:chatData', newChatData)

    // 发送消息 调用websocket实例发送消息
    // _ws
    //   .send(JSON.stringify(sendJson))
    //   .then(function (data) {
    //     console.log('本地消息发送成功')
    //     // some code...
    //   })
    //   .catch(function (data) {
    //     console.log('本地消息发送失败')
    //     // some code...
    //     // 消息发送失败 更新消息状态
    //     // that.chatData.messageState = 0;
    //     newChatData.messageState = 0
    //     that.$emit('update:chatData', newChatData)
    //   })
    // },
    // 查看图片
    // viewImg (src) {
    //   const that = this
    //   // 如果是本地图片 先return掉
    //   // if (src.indexOf('blob:') !== -1) return
    //   postFrameMessage({
    //     type: 'img',
    //     data: {
    //       src: src,
    //       file: that.chatData.file ? that.chatData.file : null
    //     }
    //   }, document.referrer.substr(0, document.referrer.length - 1))
    // },
    // 点击下载文件的回调
    // downloadFile () {
    //   // 先判断是否是本地文件
    //   const that = this
    //   if (/^blob/.test(this.chatData.src)) {
    //     this.downloadURL = this.chatData.src
    //     callback(that)
    //   } else {
    //     if (this.isDownloading) return
    //     axios.get(this.chatData.src, {
    //       responseType: 'blob',
    //       onDownloadProgress: function (progressEvent) {
    //         that.isDownloading = true
    //         that.progress = progressEvent.loaded / progressEvent.total * 100
    //       },
    //       cancelToken: new axios.CancelToken(function executor (c) {
    //         that.cancelDownloadFunc = c
    //       })
    //     }).then((res) => {
    //       this.isDownloading = false
    //       const blob = res.data
    //       this.downloadURL = URL.createObjectURL(blob)
    //       callback(that)
    //     }).catch(err => {
    //       this.isDownloading = false
    //       console.log(err)
    //     })
    //   }

    //   function callback (that) {
    //     that.$nextTick(() => {
    //       console.log('我要点击了')
    //       that.$el.querySelector('.download').click()
    //       URL.revokeObjectURL(that.downloadURL)
    //     })
    //   }
    // },
    // 如果是本地图片 则需要加载结束下拉
    // scrollBottom () {
    //   if (this.loadScroll) this.$bus.$emit('imgLoad')
    // }
  }
}
</script>

<style>
  .customer {
    display: flex;
    justify-content: flex-end;
  }

  .customer-message-container {
    position: relative;
    /* width: 100%; */
    width: auto;
    max-width: 100%;
    /* background: #3b59fe; */
    border-radius: 12px 0 12px 12px;
    /* padding: 12px; */
    font-size: 14px;
    font-weight: 400;
    color: currentColor;
    line-height: 20px;
    text-align: left;
    /* word-break: break-all; */
    /* 允许用户选择聊天记录 */
    user-select: text;
    word-break: break-word;
  }

  .customer-message {
    position: relative;
    width: auto;
    background: #3b59fe;
    border-radius: 12px 0 12px 12px;
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    color: currentColor;
    line-height: 20px;
    text-align: left;
    /* word-break: break-all; */
    /* 允许用户选择聊天记录 */
    /* user-select: text; */
  }

  /* 左侧圆环loading态  */
  /* .customer-message-loading {
  position: absolute;
  top: calc(50% - 9px);
  left: -23px;
  width: 18px;
  height: 18px;
} */

  .customer-attachment {
    position: relative;
    width: auto;
    background: #3b59fe;
    border-radius: 12px 0 12px 12px;
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    color: currentColor;
    line-height: 20px;
    text-align: left;
    cursor: pointer;
  }

  .customer-attachment span {
    text-decoration: underline currentColor;
  }

  .customer-img {
    max-height: 200px;
    max-width: 200px;
    min-width: 50px;
    min-height: 50px;
  }

  .customer-img img {
    line-height: 0;
    max-height: 200px;
    max-width: 200px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 12px 0px 12px 12px;
    border: 1px solid #d3d3d3;
    /* 不兼容ie */
    object-fit: cover;
  }

  .customer-message-state {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    min-height: 20px;
  }

  .customer-message pre {
    margin: 0;
    white-space: pre-line;
    word-break: break-word;
    /* 句末单词超出后截断 */
    /* word-break: break-all; */
    /* 句末单词超出后完整显示在下一行 */
    /* word-wrap:break-word; */
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    /* 两端对齐 */
    /* text-align: justify; */
    text-justify: inter-ideograph;
    /* text-indent: 2rem; */
    /* overflow: hidden; */
  }

  .customer-message a {
    color: white;
  }
  .customer-order {
    margin: 16px 0;
    margin-right: 26px;
  }
  .customer-orderInfo {
    background: #FFFFFF;
    width: 282px;
    box-shadow: 0px 4px 10px rgba(18, 17, 39, 0.1);
    border-radius: 8px;

  }

  .orderStatus {
    border-bottom: 1px solid #E5E5EB;
    padding: 16px;
  }

  .orderStatus>p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #6C6B80;
    margin-top: 4px !important;
  }

  .orderTitle p,
  .orderStatus p,
  .orderDetail p {
    margin: 0;
  }

  .orderTitle {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
  }

  .orderTitle p:nth-of-type(1) {
    color: #000;
  }

  .orderTitle p:nth-of-type(2) {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #727272;
    line-height: 12px;
    text-align: left;
  }

  .financialStatus {
    flex: 0.4;
    text-align: right;
    color: #6C6B80;
  }
  .order-number{
    flex: 0.5;
  }

  .orderDetail {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  .orderDetail .detailAmount {
    flex: 0.4;
  }

  .orderDetail .detailNumber {
    flex: 0.4;
  }

  .orderImg {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .orderImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .detailAmount p:nth-of-type(1),
  .detailNumber p:nth-of-type(1) {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #6C6B80;
  }

  .detailAmount p:nth-of-type(2),
  .detailNumber p:nth-of-type(2) {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #000;
  }
</style>
