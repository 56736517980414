<template>
  <div class="orderSearch">
    <nav-bar :back-page='backPage'>
      <template #left>
        <svg-icon svg-name="left-arrow" :width="iconWidth" height="20px"></svg-icon>
      </template>
      <template #center> 
        <div class="text-ellipsis">{{ navTitle }}</div>
      </template>
    </nav-bar>

    <div class="content">
      <div class="findType">
        <button :class="{'defaultType': isOrder}" @click='toOrder'><div class="text-ellipsis">{{ oNumberTitle }}</div><span class="default_border theme-solid-btn-color"></span></button>
        <button :class="{'defaultType': !isOrder}" @click='toTracking' class="number-btn"><div class="text-ellipsis">{{ tNumberTitle }}</div><span class="default_border theme-solid-btn-color"></span></button>
      </div>
      <div class="inputForm">
        <template v-if="isOrder">
          <input v-if="isOrder" ref='focusI' type="text" v-model.trim='oNumber' :placeholder="oNumberPlaceholder" class="orderInput" style="margin-top: 20px;"  @keydown.enter='search' @input="handleInput($event,'oNumberError')">
          <p class="inputTips" v-if="oNumberError">Order number cannot be empty</p>
          <input v-show='isOrder' type="text" v-model.trim='email' :placeholder="emailPlaceholder" class="orderInput" style="margin-top: 8px;" maxlength="300"  @keydown.enter='search' @input="handleInput($event,'emailError')">
          <p class="inputTips" v-if="emailError">Email format is incorrect</p>
        </template>
        <template v-else>
          <input type="text" v-model.trim='tNumber' :placeholder="tNumberPlaceholder" class="orderInput" style="margin-top: 20px;" @keydown.enter='search' @input="handleInput($event,'tNumberError')">
          <p class="inputTips" v-if="tNumberError">Logistics number cannot be empty</p>
        </template>
      </div>
      <!-- 开始查询按钮 -->
      <div class="search">
        <adaptive-button @btnClick="search" btn-theme="blue" fontSize="14px" :btn-text="buttonText" click-methods="search" btnHeight="38px" click-arg="Track" iconWidth="20px" iconHeight="20px" iconMarginR='8px' iconName='order-search-icon'></adaptive-button>
        <div class="change-find-type" @click="changeFindType">{{ changeOrderText }} ></div>
      </div>
      <!-- 这里需要注意 引导用户跳转shopify登录且用户的登录信息这些需要不存在 -->
      <div class="login-shopify theme-link-text-color" v-if="false">
        <div class="login-button"> {{ shopifyLoginReminder }} ></div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from 'components/contents/NavBar'
import AdaptiveButton from 'components/contents/AdaptiveButton'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  data () {
    return {
      oNumber: '',
      tNumber: '',
      email: '',
      showEmailTips: false,
      showNumberTips: false,
      oNumberError: false,
      tNumberError: false,
      emailError: false,
      isOrder: true,
      emailTips: 'Invalid email',
      numberTips: 'Number can not be empty',
      orderNumberTitle: 'Order Number',
      trackingNumber: 'Tracking Number',
      oNumberHolder: 'Eg. #1234',
      tNumberHolder: 'Enter your tracking number',
      emailHolder: 'Enter your email',
      navText: 'Order track',
      btnText: 'Track',
      backPage: 'home'
    }
  },
  props: {
    worktype: {
      type: String
    }
  },
  watch: {
    worktype (newVal, oldVal) {
      this.handleWorktype(newVal)
    }
  },
  components: {
    NavBar,
    AdaptiveButton
  },
  computed: {
    shopifyLoginReminder () {
      return this.textSetting.shopifyLoginReminder || ''
    },
    iconWidth () {
      return this.$store.state.isMobile ? '10px' : '20px'
    },
    textSetting () {
      return JSON.parse(this.$store.state.shopSetting.liveChat['0'])['text-settings']
    },
    order () {
      return JSON.parse(this.textSetting.order)
    },
    // 多语言部分
    navTitle () {
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[27] : "Track and manage order "
    },
    oNumberPlaceholder () {
      // return this.order.orderInput || this.$store.state.languageState ? this.$store.state.language.trackOrder[4] : this.oNumberHolder
      return this.order.orderInput || this.$store.state.languageState ? this.order.orderInput : this.oNumberHolder
    },
    tNumberPlaceholder () {
      // return this.order.trackingInput || this.$store.state.languageState ? this.$store.state.language.trackOrder[5] : this.tNumberHolder
      return this.order.trackingInput || this.$store.state.languageState ? this.order.trackingInput : this.tNumberHolder
    },
    emailPlaceholder () {
      // return this.order.orderEmail || this.$store.state.languageState ? this.$store.state.language.trackOrder[6] : this.emailHolder
      return this.order.orderEmail || this.$store.state.languageState ? this.order.orderEmail : this.emailHolder
    },
    emailErrorText () {
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[0] : this.emailTips
    },
    numberErrorText () {
      return this.$store.state.languageState ? this.$store.state.language.trackOrder[1] : this.numberTips
    },
    oNumberTitle () {
      // return this.order.orderNumber || this.$store.state.languageState ? this.$store.state.language.trackOrder[2] : this.orderNumberTitle
      return this.order.orderNumber || this.$store.state.languageState ? this.order.orderNumber : this.orderNumberTitle
    },
    tNumberTitle () {
      // return this.order.trackingNumber || this.$store.state.languageState ? this.$store.state.language.trackOrder[3] : this.trackingNumber
      return this.order.trackingNumber || this.$store.state.languageState ? this.order.trackingNumber : this.trackingNumber
    },
    buttonText () {
      // return this.order.trackBtn || this.$store.state.languageState ? this.$store.state.language.trackOrder[8] : this.btnText
      return this.order.trackBtn || this.$store.state.languageState ? this.order.trackBtn : this.btnText
    },
    changeOrderText(){
      return this.order.toManageOrders || "Log in to manage orders"
    },
    afterSalesOrderSwitch () {
      return this.$store.state.shopSetting.WidgetStatus && this.$store.state.shopSetting.WidgetStatus[0].afterSalesOrderSwitch
    },
  },
  methods: {
    ...mapMutations({
      setPage:'setPage'
    }),
    handleWorktype (type) {
      this.isOrder = type === 'order'

      this.tNumber = ''
      this.oNumber = ''
      this.email = ''
      this.showEmailTips = false
      this.showNumberTips = false
    },
    // 16进制颜色转rgba格式
    hexToRgba (hex, opacity) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return `2px solid rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)},${parseInt(result[3], 16)}, ${opacity})`
    },
    toOrder () {
      this.isOrder = true
      this.tNumber = ''
      this.oNumber = ''
      this.showEmailTips = false
      this.showNumberTips = false
    },
    toTracking () {
      this.oNumber = ''
      this.email = ''
      this.isOrder = false
      this.showEmailTips = false
      this.showNumberTips = false
    },
    //   校验订单号
    checkNumber () {
      if (this.isOrder) {
        this.showNumberTips = this.oNumber === ''
      } else {
        this.showNumberTips = this.tNumber === ''
      }
    },
    // 校验邮箱
    checkEmail () {
      // 校验email
      if (this.isOrder) {
        const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gi
        if (this.email.length === 0 || !reg.test(this.email)) {
          // console.log('wrong email')
          this.showEmailTips = true
          return false
        } else {
          this.showEmailTips = false
        }
      }
    },
    // 发送搜索请求
    search () {
      // 先判断输入的内容是否有误
      this.checkNumber()
      this.checkEmail()
      if (this.showEmailTips || this.showNumberTips) {
        return
      }
      // 去除number中的#号
      // if (this.isOrder) {
      // this.oNumber = this.oNumber.replace(/#/g, '')
      // }
      // 将查询信息存储到vuex中
      this.$store.commit('setTrackInfo', { order: this.oNumber, email: this.email, track: this.tNumber })
      // 跳转到订单详情页面
      // this.$store.commit('setPage', 'OrderDetails')
    },
    changeFindType(){
      this.setPage('OrderEmailLogin')
    },
    handleInput(e,errorName){
      if(e.target.value){
        this[errorName] = false
      }
    },
  },
  mounted () {
    // 判断是否登录，若是登录状态则返回至订单列表页
    this.backPage = this.$store.state.listToSearch ? 'OrderList' : 'home'
    // this.$store.commit('setListToSearch', false)
    this.$refs.focusI.focus()
  }
}
</script>
<style scoped>
  .login-shopify {
    width: calc(100%);
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  .content {
    width: 100%;
    padding: 16px;
    position: relative;
  }

  .findType {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: center;
  }

  .findType button {
    border: none;
    background: #fff;
    width: 156px;
    height: 36px;
    font-size: 14px;
    font-weight: bold;
    color: #B4B4B4;
    line-height: 14px;
  }
  .change-find-type{
    margin-top: 24px;
    display: inline-block;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
  }

  .findType button span {
    cursor: pointer;
  }

  .defaultType {
    position: relative;
    color: #000 !important;
    font-weight: 500 !important;
  }
  .defaultType .default_border {
    position: absolute;
    left: 0;
    bottom: -4px;
    display: inline-block;
    width: 100%;
    height: 4px;
    background-color: #2C23E5;
    border-radius: 4px;
  }

  .orderInput {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    margin-left: 0 !important;
  }

  .orderInput:hover,
  .orderInput:focus {
    outline: 2px solid var(--input-hover-color)
  }

  .search {
    width: 100%;
    margin-top: 48px;
  }

  .inputTips {
    margin-top: 4px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #FF2222;
    line-height: 14px;
  }

  .inputContent {
    position: relative;
  }
</style>
