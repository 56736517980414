<template>
  <div>
    <div class="after-sales-btns">
      <template v-for="(item,index) in btnsArr">
        <div class="btn-item" :key="index" @click.stop="handleClick(item)" v-if="item.show">{{ bunText(item.action)}}</div>
      </template>
      <!-- <div class="btn-right-icon" @click="handleClick('more')">
        <svg-icon svg-name="greater-right" width="10px" height="22px"></svg-icon>
        <svg-icon svg-name="greater-right" width="10px" height="22px"></svg-icon>
      </div> -->
    </div>
    <!-- <a-spin :spinning="spinLoading" size="large" class="track-spin">
      <div class="btn-mask-spin" v-if="spinLoading"></div>
    </a-spin> -->
  </div>
  
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import { debounce2 } from '@/utils/helper'
import moment from 'moment'
export default {
  name: 'AfterSalesBtn',
  props: {
    orderInfo:{
      type:Object,
      default(){
        return {}
      }
    },
    type:{
      type:String
    }
  },
  data () {
    return {
      btnsArr:[
      {
          key:'track',
          title:'Track',
          action:'track',
          show:true
        },
        {
          key:'cancelable',
          title:'Cancel',
          action:'cancel',
          show:false
        },
        {
          key:'returnable',
          title:'Return',
          action:'return',
          show:false
        }
        // {
        //   key:'reportissue',
        //   title:'Report issue'
        // }
      ],
      spinLoading:false
    }
  },
  computed:{
    ...mapGetters({
      getVerifyEmail: 'order/getVerifyEmail',
      shopSetting: 'shopSetting',
      TextSetting: 'TextSetting',
    }),
    chatState () {
      return Boolean(Number(this.shopSetting.enableLiveChat))
    },
    showBtns(){
      let flag = false
      flag = this.btnsArr.some(item => this.orderInfo[item.key])
      return flag
    },
    orderTextData(){
      return JSON.parse(this.TextSetting.order)
    },
    bunText(){
      return function(action){
        let str = ''
        switch (action) {
          case 'track':
            str = this.orderTextData.trackBtnText
            break;
          case 'cancel':
            str = this.orderTextData.cancelBtnText
            break;
          case 'return':
            str = this.orderTextData.returnBtnText
            break;
          default:
            break;
        }
        return str
      }
    }
  },
  watch:{
    shopSetting(newVal){
      this.handleUpdateBtn()
    }
  },
  methods: {
    ...mapMutations({
      setPage:'setPage',
      setCurrOrderAction:'order/setCurrOrderAction',
      setSpinLoading:'common/setSpinLoading',
      setAutomationChat: 'automation/setAutomationChat'
    }),
    handleClick(item){
      if(item.action == 'track'){
        this.$emit('changeSpinLoading','track',this.orderInfo)
      }else{
        this.setAutomationChat({
          userName: 'Ai-Bot',
          content:{
            orderNumber:this.orderInfo.orderNumber,
            financialStatus:this.orderInfo.financialStatus,
            img_url:this.orderInfo.img_url,
            totalNumber:this.orderInfo.totalNumber,
            totalAmount:this.orderInfo.totalAmount
          },
          type: 'Customer',
          messageType: 'order',
          profile: ''
        })
        this.setPage('chat')
      }
      // this.spinLoading = true
      // 调用操作接口
        // 1.有效可操作售后流程 --> 跳转chat页面发送order消息 --> 触发售后flow --> 等待消息交互
        // 2.无效不能操作售后流程 --> 保存订单消息，保存操作 --> 跳转邮箱验证页面置灰验证邮箱进行验证 --> 验证成功后跳转chat页面发送order消息 --> 触发售后flow --> 等待消息交互
        
    },
    // 跳转至聊天页面
    saveOrderInfo () {
      // if (this.chatState) {
        this.$store.commit('setIsFromOrder', true)
        const info = this.orderInfo
        console.log('info:', info)
        this.$store.commit('setOrderInfo', info)
        // this.setPage('login')
      // } else if (this.leaveMessageState) {
      //   this.setPage('leave-message')
      // }
    },
    handleUpdateBtn(){
      if(this.shopSetting.WidgetStatus[0].orderTrackBtnSort){
        let arr = JSON.parse(this.shopSetting.WidgetStatus[0].orderTrackBtnSort)
        let btnsArr = []
        arr.forEach((item,index) => {
          let obj = {}
          if(item == 'orderTrack'){
            obj = this.btnsArr[0]
          }else if(item == 'orderCancel'){
            obj = this.btnsArr[1]
          }else if(item == 'orderRefund'){
            obj = this.btnsArr[2]
          }
          btnsArr.push(obj)
        })
        this.btnsArr = btnsArr
      }
      if(this.type == 'orderDetail'){
        this.btnsArr = this.btnsArr.filter(item => item.action != 'track')
      }
      this.btnsArr.forEach(item => {
        if(item.action == 'cancel'){
          item.show = this.shopSetting.WidgetStatus[0].orderCancel == '1'
        }else if(item.action == 'return'){
          item.show = this.shopSetting.WidgetStatus[0].orderRefund == '1'
        }
      })
    }
  },
  mounted(){
    this.handleUpdateBtn()
  }
}
</script>

<style scoped lang="less">
.after-sales-btns{
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  .btn-item{
    color: #4C4B63;
    font-size: 12px;
    margin-right: 12px;
    line-height: 20px;
    cursor: pointer;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 6px;
    background-color: #E5E5EB;
  }
  .btn-right-icon{
    cursor: pointer;
  }
}
.btn-mask-spin{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.02);
}
</style>
