<template>
  <div class="orderList">
    <!-- 导航栏部分 -->
    <nav-bar back-page="OrderEmailLogin">
      <template #left>
        <svg-icon svg-name="left-arrow" width="20px" height="20px"></svg-icon>
      </template>
      <template #center>{{ navTitle }}</template>
    </nav-bar>
    <div class="loading-box">
      <!-- <Loading></Loading> -->
    </div>
    <!-- 订单列表内容 -->
    <scroll-container :style-text="styleText" :startWatch="true">
      <div class="switch-email" @click="handleSwitchEmail">{{ switchText }} ></div>
      <div class="listContainer" v-if="list.length > 0">
        <div class="order-list-title">{{ yourOrdersText }}</div>
        <div class="listInfo" v-for="(item, index) in list" :key="index" @click="searchOrder(item)">
          <!-- 订单信息 -->
          <div class="order-Info">
            <span class="status">{{ item.financialStatus || '' }}</span>
            <!-- 订单状态 -->
            <div class="order-header">
              <div class="order-title">
                <div class="order-number">
                  {{ orderText }} <span :title="item.orderNumber">{{ item.orderName }}</span>
                </div>
                <svg-icon class="order-icon" svg-name="direction-right" width="20px" height="20px"></svg-icon>
              </div>
              <p class="order-time">{{ orderTime(item.createTime) }}</p>
            </div>
            <!-- 订单详情 -->
            <div class="order-detail">
              <div class="order-product">
                <img class="product-img" :src="orderProduct(item.orderItems, 'img_url')" alt="" @error="handleImgError(item)" v-if="!item.imgError">
                <svg-icon svg-name='shoppingCart' width='24px' height='24px' v-else></svg-icon>
              </div>
              <div class="detailNumber">
                <p class="detail-title">{{ totalNumberText }}</p>
                <p class="detail-desc">{{ item.totalNumber }}</p>
              </div>
              <div class="detailAmount">
                <p class="detail-title">{{ totalAmountText }}</p>
                <p class="detail-desc">{{ newCurrency(item) }} {{ item.totalAmount }}</p>
              </div>
            </div>
            <AfterSalesBtn :orderInfo="item" @changeSpinLoading="handleChangeSpinLoading" type="orderList" />
          </div>
        </div>
        <Loading class="list-scroll-loading" :mask="false" v-if="listInfo.scrollLoading"></Loading>
      </div>
      <!-- 订单未找到 -->
      <div class="notFound" v-else>
        <div>
          <svg-icon svg-name="orderNotFound" width="92px" height="100px"></svg-icon>
        </div>
        <p>{{ noOrderText }}, <span class="theme-link-text-color toTrack" @click="toTracking">{{ clickHereText }}</span> {{ toTrackMoreText }}</p>
        <div class="try-button theme-bg-color" @click="reload" :class="[listInfo.loading && !listInfo.firstLoad ? 'bg-box' : '']">
          <loadingTip class="loading-btn" v-if="listInfo.loading && !listInfo.firstLoad"></loadingTip>
          <div class="btn-text theme-text-color" v-if="!(listInfo.loading && !listInfo.firstLoad)">{{ tryAgain }}</div>
        </div>
      </div>
    </scroll-container>
  </div>
</template>
<script>
// import { transCurrency } from '@/utils/helper.js'
import NavBar from "components/contents/NavBar";
import ScrollContainer from "components/contents/ScrollContainer";
import moment from "moment";
// import { getOrderList } from "@/api/trackingOrder";
import { mapState, mapGetters, mapMutations } from "vuex";
// import Loading from '@/components/common/Loading/Loading'
// import loadingTip from '@/components/contents/Loading'
import AfterSalesBtn from '@/components/contents/AfterSalesBtn'
import proImgError from '@/assets/img/proImgError.png'
export default {
  name: "orderList",
  components: {
    NavBar,
    ScrollContainer,
    // Loading,
    // loadingTip,
    AfterSalesBtn
  },
  data() {
    return {
      // transCurrency,
      proImgError,
      navText: "Order list",
      order: "Order:",
      totalNumber: "Total items",
      totalAmount: "Total amount",
      trackMore: "Order track",
      hasDetail: true,
      showTips: false,
      noOrder: "No order found yet",
      clickHere: "click here",
      toTrackMore: "to track more.",
      tryAgain: 'Try again',
      list: [],
      listInfo: {
        loading: false,
        scrollLoading: false,
        pageNum: 1,
        pageSize: 10,
        noMore: false,
        firstLoad: true
      }
    };
  },
  created() {
    this.getEmailOrderList()
  },
  computed: {
    ...mapState({
      userInfo: "userInfo",
      login: "login",
    }),
    ...mapGetters({
      searchOrderInfo: "order/searchOrderInfo",
      getVerifyEmail: 'order/getVerifyEmail'
    }),
    /**
     * 订单信息相关
     */
    orderProduct () {
      return function (orderInfo, key) {
        try {
          const orderProducts = JSON.parse(orderInfo) || []
          if (orderProducts[0])
          return orderProducts[0][key]
        } catch (e) {
          return null
        }
      }
    },
    /**
     * 订单时间转换
     */
    orderTime() {
      return function (time) {
        if (time) {
          return moment(time).format("MMM DD,YYYY");
        } else {
          return "--";
        }
      };
    },
    scrollTop() {
      return this.$store.state.isMobile ? "60px" : "74px";
    },
    iconWidth() {
      return this.$store.state.isMobile ? "10px" : "20px";
    },
    styleText() {
      // console.log(this.$store.state.boxPadding)
      // 移动端pc端
      return `top:${this.scrollTop};padding:0;height:calc(100vh - ${this.scrollTop})`;
    },
    // TODO 需要修改翻译文本
    navTitle() {
      return this.$store.state.languageState
        ? this.$store.state.language.trackOrder[20]
        : this.navText;
      // return this.navText
    },
    totalNumberText() {
      return this.$store.state.languageState
        ? this.$store.state.language.trackOrder[11]
        : this.totalNumber;
    },
    totalAmountText() {
      return this.$store.state.languageState
        ? this.$store.state.language.trackOrder[12]
        : this.totalAmount;
    },
    orderText() {
      return this.$store.state.languageState
        ? this.$store.state.language.trackOrder[15]
        : this.order;
    },
    trackMoreText() {
      return this.$store.state.languageState
        ? this.$store.state.language.trackOrder[21]
        : this.trackMore;
    },
    notFoundText() {
      return this.$store.state.languageState
        ? this.$store.state.language.trackOrder[10]
        : this.notFound;
    },
    noOrderText() {
      return this.$store.state.languageState
        ? this.$store.state.language.trackOrder[22]
        : this.noOrder;
    },
    clickHereText() {
      return this.$store.state.languageState
        ? this.$store.state.language.trackOrder[23]
        : this.clickHere;
    },
    toTrackMoreText() {
      return this.$store.state.languageState
        ? this.$store.state.language.trackOrder[24]
        : this.toTrackMore;
    },
    newCurrency() {
      return function (item){
        // let str = ''
        // let sign = this.transCurrency.currency[item.currency]
        // if(sign){
        //   str = sign
        // }else{
        //   str = item.currency
        // }
        return '$'
      }
    },
    switchText(){
      return this.$store.state.language.trackOrder[28]
    },
    yourOrdersText(){
      return this.$store.state.language.trackOrder[29]
    }
  },
  methods: {
    ...mapMutations({
      setSearchOrderInfo: 'order/setSearchOrderInfo',
      setPage:'setPage',
      setHistory:'setHistory',
      setVerifyEmail:'order/setVerifyEmail',
      setCurrOrderInfo:'setCurrOrderInfo'
    }),
    reload () {
      this.getEmailOrderList()
    },
    /**
     * @description shopify用户的订单列表相关信息
     */
    getEmailOrderList() {
      let arr = [
        {
          orderNumber: "1005",
          orderName: "#1005",
          createTime: "2024-07-10 04:03:46 -0400 -0400",
          totalNumber: "5",
          totalAmount: 734.44,
          financialStatus: "paid",
          currency: "CNY",
          trackingNumbers: "",
          orderItems: "[{\"id\":15034006274356,\"variant_id\":45349167333684,\"title\":\"123\",\"quantity\":5,\"sku\":\"\",\"product_id\":8336142434612,\"name\":\"123\",\"price\":\"129.99\",\"currency\":\"\",\"img_url\":\"https://cdn.shopify.com/s/files/1/0739/5344/4148/files/LOGO.png?v=1686534459\"}]",
          shipTo: "Guangdong China",
          userId: "6151",
          orderId: "5925469880628",
          pkId: "824346",
          sortFlag: 0,
          moneyFormat: "",
          tracking: [],
          cancelable: true,
          returnable: true,
          email: "2402984246@qq.com"
        },
        {
          orderNumber: "1004",
          orderName: "#1004",
          createTime: "2024-07-10 04:03:13 -0400 -0400",
          totalNumber: "3",
          totalAmount: 338.97,
          financialStatus: "paid",
          currency: "CNY",
          trackingNumbers: "",
          orderItems: "[{\"id\":15034005094708,\"variant_id\":47926088565044,\"title\":\"鞋子\",\"quantity\":3,\"sku\":\"\",\"product_id\":9073584865588,\"name\":\"鞋子 - 43 / 黑色\",\"price\":\"99.99\",\"currency\":\"\",\"img_url\":\"https://cdn.shopify.com/s/files/1/0739/5344/4148/files/8398.png_300.png?v=1713841862\"}]",
          shipTo: "Guangdong China",
          userId: "6151",
          orderId: "5925469454644",
          pkId: "824345",
          sortFlag: 0,
          moneyFormat: "",
          tracking: [],
          cancelable: false,
          returnable: false,
          email: "2402984246@qq.com"
        },
        {
          orderNumber: "1003",
          orderName: "#1003",
          createTime: "2024-07-10 04:02:26 -0400 -0400",
          totalNumber: "2",
          totalAmount: 293.78,
          financialStatus: "paid",
          currency: "CNY",
          trackingNumbers: "",
          orderItems: "[{\"id\":15034004406580,\"variant_id\":45349167333684,\"title\":\"123\",\"quantity\":2,\"sku\":\"\",\"product_id\":8336142434612,\"name\":\"123\",\"price\":\"129.99\",\"currency\":\"\",\"img_url\":\"https://cdn.shopify.com/s/files/1/0739/5344/4148/files/LOGO.png?v=1686534459\"}]",
          shipTo: "Guangdong China",
          userId: "6151",
          orderId: "5925468995892",
          pkId: "824344",
          sortFlag: 0,
          moneyFormat: "",
          tracking: [],
          cancelable: false,
          returnable: true,
          email: "2402984246@qq.com"
        },
        {
          orderNumber: "1002",
          orderName: "#1002",
          createTime: "2024-07-01 05:50:02 -0400 -0400",
          totalNumber: "2",
          totalAmount: 225.98,
          financialStatus: "paid",
          currency: "CNY",
          trackingNumbers: "",
          orderItems: "[{\"id\":15013120573748,\"variant_id\":47926088466740,\"title\":\"鞋子\",\"quantity\":1,\"sku\":\"\",\"product_id\":9073584865588,\"name\":\"鞋子 - 42 / 黑色\",\"price\":\"99.99\",\"currency\":\"\",\"img_url\":\"https://cdn.shopify.com/s/files/1/0739/5344/4148/files/8398.png_300.png?v=1713841862\"},{\"id\":15013120606516,\"variant_id\":47926088532276,\"title\":\"鞋子\",\"quantity\":1,\"sku\":\"\",\"product_id\":9073584865588,\"name\":\"鞋子 - 42 / 红色\",\"price\":\"99.99\",\"currency\":\"\",\"img_url\":\"https://cdn.shopify.com/s/files/1/0739/5344/4148/files/8398.png_300.png?v=1713841862\"}]",
          shipTo: "Guangdong China",
          userId: "6151",
          orderId: "5914182811956",
          pkId: "824069",
          sortFlag: 0,
          moneyFormat: "",
          tracking: [],
          cancelable: false,
          returnable: false,
          email: "2402984246@qq.com"
        },
        {
          orderNumber: "1001",
          orderName: "#1001",
          createTime: "2024-07-01 05:48:26 -0400 -0400",
          totalNumber: "1",
          totalAmount: 225.99,
          financialStatus: "paid",
          currency: "CNY",
          trackingNumbers: "154878465131310",
          orderItems: "[{\"id\":15013118935348,\"variant_id\":47917987496244,\"title\":\"机器人1\",\"quantity\":1,\"sku\":\"\",\"product_id\":9071494168884,\"name\":\"机器人1\",\"price\":\"199.99\",\"currency\":\"\",\"img_url\":\"https://cdn.shopify.com/s/files/1/0739/5344/4148/files/default_robot.png?v=1713440585\"}]",
          shipTo: "Guangdong China",
          userId: "6151",
          orderId: "5914181730612",
          pkId: "824068",
          sortFlag: 0,
          moneyFormat: "",
          tracking: [],
          cancelable: true,
          returnable: false,
          email: "2402984246@qq.com"
        }
      ]
      arr.forEach(item => {
        let orderItems = JSON.parse(item.orderItems)
        item.imgError = false
        item.orderInfo = {
          orderNumber: item.orderName,
          createDate:moment(item.createTime).format('MMM DD,YYYY'),
          totalNumber: item.totalNumber,
          totalAmount: this.newCurrency(item) + item.totalAmount,
          shipTo: item.shipTo,
          price: orderItems[0].price,
          financialStatus: item.financialStatus,
          img_url:orderItems[0].img_url || this.proImgError,
          returnable:item.returnable,
          cancelable:item.cancelable,
        }
      })
      this.list = arr
    },
    // 跳转至orderSearch页面
    toTracking(flag) {
      this.$store.commit("setListToSearch", flag);
      this.$store.commit("setPage", "OrderSearch");
    },
    // 跳转并完成查询操作
    searchOrder(item) {
      this.setCurrOrderInfo(item)
      this.setPage('OrderDetails')
    },
    handleSwitchEmail(){
      this.setPage('OrderEmailLogin')
    },
    handleImgError(order){
      this.list.forEach(item => {
        if(item.orderId == order.orderId){
          item.imgError = true
        }
      })
    },
    handleChangeSpinLoading(type,data){
      if(type == 'track'){
        this.searchOrder(data)
      }else{
      }
    }
  },
};
</script>
<style lang="less" scoped>
  @fontBlack: #000;
  @fontGray: #6C6B80;
  .loading-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .toTrack {
    cursor: pointer;
  }
  .notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 140px;
    .try-button {
      border-radius: 36px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 6px 20px;
      &.bg-box {
        background: #FFF!important;
      }
      .loading-btn {
        line-height: 0;
      }
      .btn-text {
        margin-left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 0;
      }
    }
  }
  .list-scroll-loading{
    position: relative;
  }
  .trackMore {
    text-align: right;
    padding: 0 16px;
    cursor: pointer;
  }
  .listContainer {
    padding-bottom: 16px;
  }
  .listInfo {
    margin-bottom: 16px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .order-Info {
    width: calc(100% - 32px);
    margin: 0 auto 16px;
    cursor: pointer;
    position: relative;
    .status {
      position: absolute;
      top: 16px;
      right: 16px;
      color: @fontGray;
      font-size: 14px;
      line-height: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    p {
      margin: 0;
      text-align: left;
    }
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(18, 17, 39, 0.10);
    .order-header {
      border-bottom: 1px solid #E5E5EB;
      padding: 16px;
      .order-title {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        .order-number {
          font-weight: 500;
          max-width: 50%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          .order-icon {
            margin-left: 4px;
          }
        }
      }
      .order-time {
        text-align: left;
        color: @fontGray;
      }
    }
    .order-detail {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .detailNumber {
        flex: 0.4;
        overflow: hidden;
        word-wrap: break-word;
      }
      .detailAmount {
        flex: 0.4;
        overflow: hidden;
        word-wrap: break-word;
      }
      .order-product {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        .product-img {
          width: 48px;
          height: 48px;
          line-height: 0;
          border-radius: 4px;
        }
      }
      .detail-title {
        font-size: 12px;
        color: @fontGray;
        margin-bottom: 4px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100px;
      }
      .detail-desc {
        font-size: 16px;
        color: @fontBlack;
        font-weight: 600;
      }
    }
  }
  .switch-email{
    color: var(--primary-color);
    display: inline-block;
    position: absolute;
    right: 16px;
    cursor: pointer;
    top: 12px;
  }
  .order-list-title{
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #000;
    margin:12px 16px;
    text-align: left;
  }
  .orderBtns{
    padding: 16px;
  }
</style>
